<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'responsable-commercial' ||
        getUser.role.slug === 'community-manager'
    "
  >
   
    <b-modal
      id="modal-new-blog-category"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Ajout d'une nouvelle catégorie"
    >
      <validation-observer
        #default="{}"
        ref="addNewBlogCategoryForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModalAddNewBlogCategory"
          @submit.prevent="applyAddNewBlogCategoryAction"
        >
          <!-- Email -->
          <validation-provider
            #default="{ errors }"
            name="Titre"
            rules="required"
          >
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <b-form-input
                id="nom"
                v-model="category.name"
                type="text"
                :state="errors.length > 0 ? false : null"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isAddNewBlogCategoryLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isAddNewBlogCategoryLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Enregistrer</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-breadcrumb class="mb-2">
        <b-breadcrumb-item to="/blog">Blog</b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link
            :to="{
              name: 'blog-detail',
              params: { id: this.$route.params.id } 
            
            }"
            >Détails</router-link
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item active>Modifier</b-breadcrumb-item>
    </b-breadcrumb>
    <div
      v-if="isPageLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <div v-else>
      <b-card
        v-if="Object.keys(blog).length"
        class="blog-edit-wrapper"
      >
        <!-- media -->
        <b-media
          no-body
          vertical-align="center"
        >
          <b-media-aside class="mr-75">
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              :text="
                avatarText(`${blog.author.first_name} ` + ` ${blog.author.last_name}`)
              "
              :src="blog.author.profile_image"
              class="badge-minimal"
              badge-variant="success"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-25">
              {{ blog.author.first_name }} {{ blog.author.last_name }}
            </h6>
            <b-card-text>{{ createdTime }}</b-card-text>
          </b-media-body>
        </b-media>
        <!--/ media -->

        <!-- form -->
        <validation-observer
          #default="{}"
          ref="addNewArticle"
        >
          <b-form
            id="new-article-form"
            class="mt-2"
            @submit.prevent="applyUpdateArticleAction()"
          >
            <b-row>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Titre de l'article"
                  rules="required"
                >
                  <b-form-group
                    label="Titre de l'article"
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <b-form-input
                      id="blog-edit-title"
                      v-model="blog.title"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider #default="{ errors }">
                  <b-form-group
                    label="Slug de l'article"
                    label-for="blog-edit-slug"
                    class="mb-2"
                  >
                    <b-form-input
                      id="blog-edit-slug"
                      v-model="blog.slug"
                      readonly
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Catégories"
                  rules="required"
                >
                  <b-form-group
                    label="Catégories"
                    label-for="Catégories"
                  >
                    <v-select
                      id="category"
                      v-model="blog.category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="(category) => category.id"
                      label="name"
                      :options="categoryOption"
                      :state="errors.length > 0 ? false : null"
                    >
                      <template #option="{ name }">
                        <b-row>
                          <b-col>
                            <span class="text-white">
                              <strong class="text-info"> {{ name }} </strong>
                            </span>
                          </b-col>
                          <br>
                        </b-row>
                      </template>
                      <template v-slot:no-options>
                        <b-spinner
                          v-if="isBlogCatetegoriesLoading"
                          style="width: 2.5rem; height: 2.5rem"
                          class="align-middle text-info"
                        />
                        <div v-else>
                          <span
                            class="mt-1 font-medium-1"
                          >Aucune catégorie ne correspond à ces détails</span>
                          <li
                            class="text-success mt-1 mb-1 font-medium-1"
                            style="text-align: center; cursor: pointer"
                            @click="$bvModal.show('modal-new-blog-category')"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span
                              class="text-center align-middle ml-25"
                            >Créer une catégorie</span>
                          </li>
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="Contenu de l'article"
                  rules="required"
                >
                  <b-form-group
                    label="Contenu de l'article"
                    label-for="blog-content"
                    class="mb-2"
                  >
                    <quill-editor
                      id="blog-content"
                      v-model="blog.content"
                      :options="snowOption"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                class="mb-2"
              >
                <div class="border rounded p-2">
                  <h4 class="mb-1">
                    Bannière de l'article
                  </h4>
                  <b-media
                    no-body
                    vertical-align="center"
                    class="flex-column flex-md-row"
                  >
                    <b-media-aside v-if="blog.image != null">
                      <b-img
                        ref="refPreviewEl"
                        :src="image"
                        height="300"
                        width="400"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                    </b-media-aside>
                    <b-media-body>
                      <small class="text-muted">
                        JPG, GIF, JPEG ou PNG autorisées.Image de résolution
                        800x400, image de taille 10mb.</small>

                      <validation-provider
                        #default="{ errors }"
                        name="Bannière de l'article"
                        rules="required"
                      >
                        <div class="d-inline-block">
                          <b-form-file
                            ref="refInputEl"
                            v-model="image"
                            accept=".jpg, .png, .gif"
                            placeholder="Choisissez un fichier"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                      </validation-provider>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-50"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                  class="mr-1"
                >
                  <div v-if="isUpdateBlogArticleLoading">
                    <b-spinner small />
                  </div>
                  <span v-else>Modifier l'article</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <!--/ form -->
      </b-card>
    </div>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BModal,
  BMediaBody,
  BForm,
  BRow,
  BSpinner,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BModal,
    BForm,
    BLink,
    BSpinner,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BBreadcrumb,
    BBreadcrumbItem
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      required,
      isUpdateBlogArticleLoading: false,
      isPageLoading: false,
      createdTime: '',
      image: null,
      blog: {
        title: '',
        category_id: '',
        slug: '',
        content: '',
        image: null,
      },

      category: {
        name: '',
      },
      categoryOption: [],
      snowOption: {
        theme: 'snow',
      },
    }
  },
  created() {
    this.categoryOption = this.blogsCategories
    if (this.blogArticle !== null) {
      this.blog = this.blogArticle
      this.image = this.blog.image
    } else {
      const id = this.$route.params.id.split('-').pop()
      this.appyGetBlogArticleDetail(id)
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters(['isBlogCatetegoriesLoading']),
    ...mapGetters('blog', ['blogsCategories', 'blogArticle']),

  },
  watch: {
    'blog.title': function (val) {
      this.blog.slug = this.slugify(val)
    },
    image(val) {
      if (val !== this.blog.image) {
        this.image = URL.createObjectURL(val)
        this.blog.image = val
      }
    },
    isBlogCatetegoriesLoading(val) {
      if (val === false) {
        this.categoryOption = this.blogsCategories
      }
    },
  },
  mounted() {
    this.createdTime = new Date().toLocaleDateString()
  },

  methods: {
    ...mapActions('blog', [
      'createBlogCateoryAction',
      'createBlogArticleAction',
      'getBlogArticleDetail', 'updateBlogArticleAction',
    ]),
    slugify(str) {
      return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
    },
    hideModalAddNewBlogCategory() {
      this.$bvModal.hide('modal-new-blog-category')
      this.category.name = ''
    },
    appyGetBlogArticleDetail(id) {
      this.isPageLoading = true
      this.getBlogArticleDetail(id)
        .then(response => {
          this.blog = response.data
          this.isPageLoading = false
          this.image = response.data.image
        })
        .catch(error => {
          this.isPageLoading = false
        })
    },
    applyUpdateArticleAction() {
      this.$refs.addNewArticle.validate().then(succes => {
        if (succes) {
          this.isUpdateBlogArticleLoading = true
          this.image.includes('s3') ? delete this.blog.image : ''
          this.updateBlogArticleAction({ payload: this.blog, id: this.blog.id })
            .then(response => {
              this.isUpdateBlogArticleLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },

                this.$router.push({
                  name: 'blog-detail',
                  params: { id: this.blog.slug.concat('-', response.data.id) },
                }),
              )
            })
            .catch(error => {
              this.isUpdateBlogArticleLoading = false
              if (error.response) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'AlertTriangleIcon',
                      text: `${error.response.data.message}`,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              }
            })
        }
      })
    },
    applyAddNewBlogCategoryAction() {
      this.$refs.addNewBlogCategoryForm.validate().then(success => {
        if (success) {
          this.isAddNewBlogCategoryLoading = true
          this.createBlogCateoryAction(this.category)
            .then(response => {
              this.isAddNewBlogCategoryLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.categoryOption = this.blogsCategories
              this.hideModalAddNewBlogCategory()
            })
            .catch(error => {
              this.isAddNewBlogCategoryLoading = false
            })
        }
      })
    },
  },
}
</script>

  <style lang="scss" >
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  @import "@core/scss/vue/pages/page-blog.scss";
  </style>
  <style lang="scss" scoped>
  .ql-toolbar .ql-stroke {
    fill: none !important;
    stroke: rgb(197, 15, 15) !important;
  }

  .ql-toolbar .ql-fill {
    fill: rgb(201, 36, 36) !important;
    stroke: none !important;
  }

  .ql-toolbar .ql-picker {
    color: rgb(196, 32, 32) !important;
  }
  </style>
