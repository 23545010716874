var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.getUser.role.slug === 'super-administrateur' ||
    _vm.getUser.role.slug === 'responsable-commercial' ||
      _vm.getUser.role.slug === 'community-manager'
  )?_c('div',[_c('b-modal',{attrs:{"id":"modal-new-blog-category","cancel-variant":"outline-secondary","hide-footer":"","centered":"","no-close-on-backdrop":"","no-close-on-esc":"","title":"Ajout d'une nouvelle catégorie"}},[_c('validation-observer',{ref:"addNewBlogCategoryForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',{staticClass:"p-2",attrs:{"autocomplete":"off"},on:{"reset":function($event){$event.preventDefault();return _vm.hideModalAddNewBlogCategory.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.applyAddNewBlogCategoryAction.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Titre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nom","label-for":"nom"}},[_c('b-form-input',{attrs:{"id":"nom","type":"text","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", $$v)},expression:"category.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isAddNewBlogCategoryLoading,"variant":"primary","type":"submit"}},[(_vm.isAddNewBlogCategoryLoading)?_c('div',[_c('span',[_vm._v(" Chargement ... ")]),_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v("Enregistrer")])]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Annuler ")])],1)],1)]}}],null,false,883792402)})],1),_c('b-breadcrumb',{staticClass:"mb-2"},[_c('b-breadcrumb-item',{attrs:{"to":"/blog"}},[_vm._v("Blog")]),_c('b-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'blog-detail',
            params: { id: this.$route.params.id } 
          
          }}},[_vm._v("Détails")])],1),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("Modifier")])],1),(_vm.isPageLoading)?_c('div',{staticClass:"d-flex justify-content-center mb-1",staticStyle:{"padding-top":"30vh"}},[_c('b-spinner',{staticClass:"mr-1",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"info"}})],1):_c('div',[(Object.keys(_vm.blog).length)?_c('b-card',{staticClass:"blog-edit-wrapper"},[_c('b-media',{attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-avatar',{staticClass:"badge-minimal",attrs:{"size":"40","variant":"light-primary","badge":"","text":_vm.avatarText((_vm.blog.author.first_name) + " " + " " + (_vm.blog.author.last_name)),"src":_vm.blog.author.profile_image,"badge-variant":"success"}})],1),_c('b-media-body',[_c('h6',{staticClass:"mb-25"},[_vm._v(" "+_vm._s(_vm.blog.author.first_name)+" "+_vm._s(_vm.blog.author.last_name)+" ")]),_c('b-card-text',[_vm._v(_vm._s(_vm.createdTime))])],1)],1),_c('validation-observer',{ref:"addNewArticle",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',{staticClass:"mt-2",attrs:{"id":"new-article-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyUpdateArticleAction()}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Titre de l'article","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Titre de l'article","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.blog.title),callback:function ($$v) {_vm.$set(_vm.blog, "title", $$v)},expression:"blog.title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Slug de l'article","label-for":"blog-edit-slug"}},[_c('b-form-input',{attrs:{"id":"blog-edit-slug","readonly":""},model:{value:(_vm.blog.slug),callback:function ($$v) {_vm.$set(_vm.blog, "slug", $$v)},expression:"blog.slug"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Catégories","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Catégories","label-for":"Catégories"}},[_c('v-select',{attrs:{"id":"category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (category) { return category.id; },"label":"name","options":_vm.categoryOption,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"option",fn:function(ref){
          var name = ref.name;
return [_c('b-row',[_c('b-col',[_c('span',{staticClass:"text-white"},[_c('strong',{staticClass:"text-info"},[_vm._v(" "+_vm._s(name)+" ")])])]),_c('br')],1)]}},{key:"no-options",fn:function(){return [(_vm.isBlogCatetegoriesLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('div',[_c('span',{staticClass:"mt-1 font-medium-1"},[_vm._v("Aucune catégorie ne correspond à ces détails")]),_c('li',{staticClass:"text-success mt-1 mb-1 font-medium-1",staticStyle:{"text-align":"center","cursor":"pointer"},on:{"click":function($event){return _vm.$bvModal.show('modal-new-blog-category')}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}}),_c('span',{staticClass:"text-center align-middle ml-25"},[_vm._v("Créer une catégorie")])],1)])]},proxy:true}],null,true),model:{value:(_vm.blog.category_id),callback:function ($$v) {_vm.$set(_vm.blog, "category_id", $$v)},expression:"blog.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Contenu de l'article","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Contenu de l'article","label-for":"blog-content"}},[_c('quill-editor',{attrs:{"id":"blog-content","options":_vm.snowOption},model:{value:(_vm.blog.content),callback:function ($$v) {_vm.$set(_vm.blog, "content", $$v)},expression:"blog.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Bannière de l'article ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[(_vm.blog.image != null)?_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.image,"height":"300","width":"400"}})],1):_vm._e(),_c('b-media-body',[_c('small',{staticClass:"text-muted"},[_vm._v(" JPG, GIF, JPEG ou PNG autorisées.Image de résolution 800x400, image de taille 10mb.")]),_c('validation-provider',{attrs:{"name":"Bannière de l'article","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:"d-inline-block"},[_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","placeholder":"Choisissez un fichier"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)]),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","type":"submit"}},[(_vm.isUpdateBlogArticleLoading)?_c('div',[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v("Modifier l'article")])])],1)],1)],1)]}}],null,false,4012133254)})],1):_vm._e()],1)],1):_c('div',[_c('not-authorized')],1)}
var staticRenderFns = []

export { render, staticRenderFns }